.container {
    display: flex;
    align-items: center;
}
.chip_draft {
    background-color: var(--main-blue-color) !important;
}

.chip_published {
    background-color: var(--green) !important;
}

.chip_not_synced {
    background-color: var(--yellow) !important;
}

.chip_unpublished {
    background-color: var(--pink-dark) !important;
}

.label {
    font-size: 10px !important;
    margin-top: 2px;

    letter-spacing: 0.4px;
    text-transform: uppercase;
}

.minimalChip {
    --size: 16px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
    height: var(--size);
    width: var(--size);
    border-radius: calc(var(--size) / 2);
}

.minimalContainer {
    display: flex;
    align-items: center;
}

.warningIcon {
    color: var(--dark-red) !important;
}

.container .warningIcon {
    margin-right: 4px;
}
