.container {
}

.title {
    font-size: 14px;
    opacity: 0.8;
}

.item {
    align-items: center;
    display: flex;
    position: relative;
    padding: 16px 8px;
}

.itemDeprecated {
    opacity: 0.5;
}

.item {
    border-bottom: 1px solid rgba(var(--green-dark-rgb), 0.1);
}

.itemThumb {
    position: relative;
    width: 48px;
}

.itemThumb img {
    display: block;
    max-width: 100%;
}

.itemNoImage {
    display: flex;
    height: 48px;
    width: 100%;
    align-items: center;
    justify-content: center;
    opacity: 0.2;
}

.itemStatus {
    position: absolute;
    right: 0;
    bottom: 4px;
}

.itemText {
    display: flex;
    flex: 1;
    margin-left: 16px;
}

.itemTitle {
    margin-left: 4px;
}

.itemVersion {
    font-weight: var(--fw-bold);
}

.itemLink {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
}

.itemLink:hover {
    background-color: rgba(var(--green-dark-rgb), 0.1);
}
