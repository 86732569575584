.container {
    padding-top: 40px;
    padding-bottom: 100px;
}

.section {
    margin-bottom: 88px;
}

.section:not(:first-of-type) {
    margin-top: 48px;
}

.sectionHead {
    margin-bottom: 32px;
}

.sectionTitle {
    font-size: 14px;
    font-weight: var(--fw-black);
    margin-bottom: 8px;
    text-transform: uppercase;
    letter-spacing: 0.4px;
}

.sectionText {
    opacity: 0.7;
    font-size: 14px;
}

.sectionContent {
    margin-top: 48px;
}

.propertiesAndVersion {
    display: flex;
}

.propertiesContainer {
}

.thumbnail {
    border: 1px dashed rgba(var(--green-dark-rgb), 0.2);
    border-radius: 8px;
    padding: 16px;
    width: 160px;
}

.noImage {
    display: flex;
    opacity: 0.5;
    flex-direction: column;
    height: 120px;
    width: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.noImageText {
    font-size: 12px;
    margin-top: 8px;
}

.thumbnail img {
    display: block;
    max-width: 100%;
}

.properties {
    flex: 1;
    padding-top: 24px;
}

.properties + .properties {
    margin-left: 64px;
}

.property {
    max-width: 360px;
}

.propertiesRow {
    display: flex;
}

.propertiesRow + .propertiesRow {
    margin-top: 48px;
}

.propertiesRowItem + .propertiesRowItem {
    margin-left: 64px;
}

.propertiesRowItem > .property + .property {
    margin-top: 40px;
}

.propertyTitle {
    text-transform: uppercase;
    font-size: 10px;
    font-weight: var(--fw-bold);
    opacity: 0.4;
    letter-spacing: 0.4px;
}

.propertyValue {
    margin-top: 4px;
}

.propertyValueEmpty {
    opacity: 0.8;
}

.createdByImage {
    display: block;
    max-width: 100%;
}

.version {
    align-self: flex-start;
    background-color: var(--white);
    border-radius: 8px;
    padding: 24px;
    /* margin-left: 88px; */
}

.latestVersionInfo {
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: var(--fw-bold);
    opacity: 0.8;
    letter-spacing: 0.4px;
}

.latestVersionInfoSuccess .latestVersionInfoText {
    color: var(--purple);
}
.latestVersionInfoError .latestVersionInfoText {
    color: var(--error-color);
}

.latestVersionIcon {
    margin-right: 4px;
}

.latestVersionIconSuccess {
    color: var(--purple) !important;
}

.latestVersionIconError {
    color: var(--error-color) !important;
}

.otherVersions {
    margin-top: 24px;
}

.otherVersionsList {
    margin-top: 24px;
}

.btnMakeLive {
    margin-left: 24px;
}
.empty {
    align-items: center;
    display: inline-flex;
    padding: 24px;
    border-radius: 6px;
    background-color: var(--main-pink-color);
}

.emptyText {
    margin-left: 8px;
}
