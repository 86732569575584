.container :global(.MuiDrawer-paperAnchorDockedLeft) {
    border: 0 !important;
}

.container {
    height: 100%;
}

.content {
    height: 100%;
}

.contentWithSidebar {
    padding-left: var(--sidebar-width);
}
