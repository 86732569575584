.container {
    --button-size: 96px;
}

.grid {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
}

.gridItem {
    width: calc(12.5% - 14px);
}

.addButton {
    border: 1px solid rgba(0, 0, 0, 0.23) !important;
    border-radius: 6px !important;
    /* width: var(--button-size); */
    /* height: var(--button-size); */
}

.addButton:hover {
    border-color: rgba(0, 0, 0, 0.87) !important;
}

.selectedSessions {
    /* display: flex; */
}

.session {
    width: 100% !important;
}

.session::after {
    content: '';
    display: block;
    padding-bottom: 100%;
}

.selectedSession {
    border: 1px solid rgba(0, 0, 0, 0.23) !important;
    border-radius: 6px !important;
    position: relative;
    /* height: var(--button-size); */
    /* width: var(--button-size); */
}

.sessionContent {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 8px;
}

.sessionThumb {
    flex: 1;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
}

.sessionTitle {
    font-size: 8px;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    overflow: hidden;
}
