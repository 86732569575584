.header {
    background-color: var(--off-white);
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    left: var(--sidebar-width);
    right: 0;
    top: 0;
    height: var(--header-height);
    padding: 0 40px;
    z-index: var(--zi-header);
}

.left {
    align-items: center;
    display: flex;
    position: relative;
}

.backButton {
    position: absolute !important;
    left: 0;
    top: 50%;
    transform: translate(-100%, -50%);
    padding: 8px !important;
}

.backButtonIcon {
    font-size: 20px !important;
}

.headerTitle {
    font-size: 18px;
    font-weight: var(--fw-bold);
    margin: 0;
}

.titleInput input {
    font-size: 18px;
    font-weight: var(--fw-bold);
}

.titleAdornment {
    margin-left: 24px;
}
