.experts {
    display: block;
    text-align: left;
    color: var(--green-dark);
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
    border-radius: 6px;
    position: relative;
}

.experts::after {
    content: '';
    display: block;
    padding-bottom: 100%;
}

.background {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 6px;
    opacity: 0.4;
}

.expertsContent {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 8px;
}

.expertsThumb {
    flex: 1;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
}

.expertsTitle {
    font-size: 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    overflow: hidden;
}

.experts:hover {
    border-color: rgba(0, 0, 0, 0.87) !important;
}

.expertsWithButtons:hover .expertsButtons {
    opacity: 1;
}

.expertsWithButtons:hover .expertsThumb {
    opacity: 0;
}

.expertsWithButtons:hover .expertsTitle {
    opacity: 0;
}

.expertsWithButtons:hover .statusChip {
    display: none;
}

.expertsButtons {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    opacity: 0;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    /* background-color: rgba(255, 255, 255, 0.8); */
    border-radius: 6px;
    padding: 8px;
}

.deleteButton {
    margin: 0 auto !important;
    display: block !important;
}

.arrowButtons {
    display: flex;
}

.emptyExperts {
    border: 1px solid rgba(0, 0, 0, 0.23) !important;
    width: 100%;
}

.statusChip {
    position: absolute;
    right: 8px;
    top: 8px;
}

.warningIcon {
    position: absolute;
    right: 8px;
    top: 8px;
}
