.container {
    display: block;
}

.divider {
    align-self: stretch;
    display: flex;
    justify-content: center;
    width: 32px;
}

.new {
    margin-top: 16px;
}

.experts {
    display: flex;
    flex-direction: column;
    height: 320px;
    overflow-y: scroll;
    position: relative;
}

.expert {
    margin-bottom: 16px;
}

.expertContent {
    overflow: hidden;
    white-space: nowrap;
}

.expertTitle {
    text-overflow: ellipsis;
}
