.header {
    background-color: var(--white);
    align-items: center;
    display: flex;
    height: var(--header-height);
    padding: 0 16px;
}

.headerText {
    flex: 1;
}

.headerTitle {
    /* color: var(--white); */
    font-size: 12px;
    font-weight: var(--fw-bold);
    letter-spacing: 0.4px;
    text-transform: uppercase;
}

.editorWrapper {
    min-height: 400px;
}

.editor {
    padding: 0 24px;
}

.textareaWrapper {
    padding: 24px;
}
