.section {
    margin-bottom: 88px;
}

.section:not(:first-of-type) {
    margin-top: 48px;
}

.sectionHead {
    display: flex;
    margin-bottom: 32px;
}

.sectionHeadContent {
    flex: 1;
}

.sectionTitle {
    font-size: 14px;
    font-weight: var(--fw-black);
    margin-bottom: 8px;
    text-transform: uppercase;
    letter-spacing: 0.4px;
}

.sectionText {
    opacity: 0.7;
    font-size: 14px;
}

.sectionContent {
    margin-top: 48px;
}
