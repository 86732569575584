.container {
    padding: 24px;
}

.button {
    display: block;
    background-color: rgba(var(--green-dark-rgb), 0.1) !important;
    border: 1px solid rgba(0, 0, 0, 0.23) !important;
    border-radius: 6px !important;
    opacity: 0.6;
    position: relative;
    padding: 16px 0;
    border: 1px solid;
    width: 100%;
}

.button:hover {
    border-color: rgba(0, 0, 0, 0.87) !important;
}

.buttonActive {
    background-color: var(--white-off) !important;
    border-color: var(--purple) !important;
    /* border-width: 2px !important; */
    opacity: 1;
}
.button + .button {
    margin-top: 16px;
}

.buttonCheck {
    position: absolute;
    top: 8px;
    right: 8px;
}
