.activity {
    display: block;
    text-align: left;
    color: var(--green-dark);
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
    border-radius: 6px;
    position: relative;
}

.activity::after {
    content: '';
    display: block;
    padding-bottom: 100%;
}

.background {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 6px;
    opacity: 0.4;
}

.activityContent {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 8px;
}

.activityThumb {
    flex: 1;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
}

.activityTitle {
    font-size: 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    overflow: hidden;
}

.activity:hover {
    border-color: rgba(0, 0, 0, 0.87) !important;
}

.activityWithButtons:hover .activityButtons {
    opacity: 1;
}

.activityWithButtons:hover .activityThumb {
    opacity: 0;
}

.activityWithButtons:hover .activityTitle {
    opacity: 0;
}

.activityWithButtons:hover .statusChip {
    display: none;
}

.activityButtons {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    opacity: 0;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    /* background-color: rgba(255, 255, 255, 0.8); */
    border-radius: 6px;
    padding: 8px;
}

.deleteButton {
    margin: 0 auto !important;
    display: block !important;
}

.arrowButtons {
    display: flex;
}

.emptyActivity {
    border: 1px solid rgba(0, 0, 0, 0.23) !important;
    width: 100%;
}

.statusChip {
    position: absolute;
    right: 8px;
    top: 8px;
}

.warningIcon {
    position: absolute;
    right: 8px;
    top: 8px;
}
