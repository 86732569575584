.content {
    padding-top: var(--header-height);
}

.contentPaddingLeft {
    padding-left: 40px;
}

.contentPaddingRight {
    padding-right: 40px;
}

.fab {
    position: fixed !important;
    right: 40px;
    bottom: 40px;
}

.backdrop {
    background-color: rgba(var(--off-white-rgb), 0.8) !important;
    z-index: var(--zi-loader) !important;
    flex-direction: column;
}

.loadingText {
    font-size: 12px;
    margin-top: 16px;
    font-weight: var(--fw-bold);
}
