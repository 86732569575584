.container {
    align-items: center;
    background-color: var(--main-bg-color);
    display: flex;
    justify-content: center;
    flex: 1;
    height: 100%;
}

.containerFixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: auto;
    background-color: rgba(var(--off-white-rgb), 0.3);
    z-index: var(--zi-loader);
}
