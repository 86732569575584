.mainContainer {
  padding: 3%;
}
.titleContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: center;
}

.tableItem {
  text-decoration: none;
}
.tableItem:hover {
  background-color: var(--main-pink-color);
}

.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.buttonContainer {
  margin-right: 5vw;
}

.row {
  transition: background-color 0.3s ease-in-out;
}
.row:hover {
  background-color: rgba(var(--green-dark-rgb), 0.05);
  cursor: pointer;
}
.mainTable {
  margin-top: 24px;
  width: 100%;
}
.thumbnail {
  display: block;
  max-width: 100%;
}
.thumbnailWrapper {
  height: 64px;
  width: 64px;
  display: block;
  position: relative;
}

.noImage {
  display: flex;
  height: 64px;
  width: 64px;
  align-items: center;
  justify-content: center;
  opacity: 0.3;
}

.status {
  position: absolute;
  bottom: 0;
  transform: translateY(50%);
}

.showUnpublished {
  padding-left: 2%;
}

.cell {
  font-size: 14px;
  padding-top: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid rgba(var(--green-dark-rgb), 0.1);
  /* white-space: nowrap; */
}

/* .cell:first-child {
  padding-left: 40px;
} */

.cell:last-child {
  padding-right: 40px;
}

.cellBold {
  font-weight: var(--fw-bold);
  max-width: 160px;
}
.columnTitle {
  opacity: 0.6;
  letter-spacing: 0.2px;
  align-items: center;
  display: flex;
  text-transform: uppercase;
  font-size: 10px;
}

.search {
  margin-left: 40px;
  margin-bottom: 24px;
}

