.container {
    align-items: center;
    display: inline-flex;
    padding: 24px;
    border-radius: 6px;
    background-color: var(--main-pink-color);
}

.text {
    margin-left: 8px;
}
