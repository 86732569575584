.wrapper {
    display: flex;
    flex-direction: row;
}

.wrapper:hover .container {
    /* background-color: white; */
}
.container {
    padding: 8px 16px;
    border: 1px dashed rgba(var(--green-dark-rgb), 0.2);
    border-radius: 8px;
    background-color: var(--off-white);
    display: flex;
    flex: 1;
    flex-direction: row;
    margin-left: 8px;
}

.wrapper + .wrapper {
    margin-top: 8px;
}

.content {
    flex: 1;
    display: flex;
    align-items: center;
    height: 100%;
}

.header {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.type {
    text-transform: uppercase;
    font-size: 10px;
    font-weight: var(--fw-bold);
    opacity: 0.4;
    letter-spacing: 0.4px;
    flex: 1;
    /* margin-bottom: 8px; */
}

/* Drag */
.dragHandle {
    padding-top: 16px;
    cursor: move;
}

.dragHandleIcon {
    color: var(--gray);
}

/* 
 * Previews
 */
.preview {
    width: 100%;
}
.previewDivider {
    height: 2px;
    background-color: var(--green-dark);
    width: 90%;
}

.previewImage {
    padding-right: 16px;
}

.previewCallout {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.previewCalloutIcon {
    opacity: 0.8;
}

.previewCalloutMarkdown {
    margin-left: 16px;
    color: var(--purple);
}

.previewText {
    font-size: 14px;
}

.previewTitle {
    font-size: 20px;
    font-weight: var(--fw-bold);
}
