.header {
    background-color: var(--green-dark);
    align-items: center;
    display: flex;
    height: var(--header-height);
    padding: 0 16px;
}

.headerText {
    flex: 1;
}

.headerTitle {
    color: var(--white);
    font-size: 12px;
    font-weight: var(--fw-bold);
    letter-spacing: 0.4px;
    text-transform: uppercase;
}

.bottomBar {
    background-color: var(--gray-light);
    display: flex;
    padding: 8px 16px;
}

.bottomBarContent {
    display: flex;
    flex: 1;
}

.bottomBarContentItem {
    margin-left: 16px;
}

.datepicker {
    /* display: none !important; */
    visibility: hidden;
    overflow: hidden;
    height: 0;
}

.container {
    height: 100%;
}

.container > div {
    height: 100%;
}

.closeButton {
    color: var(--white) !important;
}

:global(.jsoneditor) {
    border: 0;
}

:global(.jsoneditor-menu) {
    background-color: var(--green-dark);
    border-bottom: 0;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
}
:global(.jsoneditor-menu > button) {
    color: var(--green-dark) !important;
}

:global(.jsoneditor-statusbar) {
    padding: 0 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
