.blockTitle {
    font-size: 12px;
    font-weight: var(--fw-bold);
    margin-bottom: 8px;
}

.block + .block {
    margin-top: 40px;
}

.grid {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
}

.gridItem {
    width: calc(10% - 14px);
}

.blockItem {
    background-color: var(--bg-story);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    color: var(--green-dark);
    padding: 16px 8px;
    text-decoration: none;
}

.blockItem:hover {
    border-color: rgba(0, 0, 0, 0.87) !important;
}

.blockItemScreen {
    text-transform: uppercase;
    font-size: 10px;
    letter-spacing: 0.4px;
    font-weight: var(--fw-bold);
    opacity: 0.7;
    margin-bottom: 4px;
}

.blockItemTitle {
    font-size: 14px;
}
