.container {
    box-sizing: border-box;
    position: relative;
    height: 200px;
    width: 100%;
}

.container * {
    box-sizing: border-box;
}

.fieldset {
    padding: 0 8px;
    margin: 0;
    position: absolute;
    top: -5px;
    overflow: hidden;
    left: 0;
    right: 0;
    bottom: 0;
    font-size: 1rem;
    border-color: rgba(0, 0, 0, 0.23);
    border-width: 1px;
    border-radius: 4px;
    pointer-events: none;
}

.wrappingLabel {
    cursor: pointer;
}

.wrappingLabel:hover .fieldset {
    border-color: rgba(0, 0, 0, 0.87);
}

.containerError .fieldset {
    border-color: var(--error-color) !important;
}

.legend {
    display: block;
    max-width: 1000px;
    padding: 0;
    text-align: left;
    height: 11px;
    font-size: 0.75em;
    /* transform: scale(0.75); */
    width: auto;
}

.legend span {
    display: inline-block;
    padding-left: 5px;
    padding-right: 5px;
    visibility: hidden;
}

.input {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.label {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(14px, -6px) scale(0.75);
    color: rgba(0, 0, 0, 0.54);
    line-height: 1;
    transform-origin: top left;
    z-index: 2;
}

.containerError .label {
    color: var(--error-color);
}

.metadata {
    margin-bottom: 24px;
}

.metadataRow {
    display: flex;
}

.metadataText + .metadataText {
    margin-left: 8px;
}

.metadataText {
    text-transform: uppercase;
    opacity: 0.7;
    letter-spacing: 0.4px;
    font-size: 12px;
    margin-top: 4px;
}

.metadataTextBold {
    font-weight: var(--fw-bold);
}

.typeIcon {
    opacity: 0.6;
    margin-bottom: 4px !important;
}

.inputWithImage {
    /* padding: 16px !important; */
}

.input {
    padding: 24px;
}

.image {
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
}

.audio {
    position: absolute;
    bottom: 16px;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: 10;
}

.editOverlay {
    background-color: rgba(var(--off-white-rgb), 0.8);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 1px;
    left: 1px;
    right: 1px;
    bottom: 1px;
    z-index: 1;
    opacity: 0;
}

.inputWithValue:hover .editOverlay {
    opacity: 1;
}
