.container {
    display: flex;
}

.containerFullWidth {
    display: block;
}

.side {
    width: calc(50% - 16px);
}

.divider {
    align-self: stretch;
    display: flex;
    justify-content: center;
    width: 32px;
}

.new {
    margin-top: 16px;
}

.programs {
    display: flex;
    flex-direction: column;
    height: 320px;
    overflow-y: scroll;
    position: relative;
}

.program {
    margin-bottom: 16px;
}

.programContent {
    overflow: hidden;
    white-space: nowrap;
}

.programTitle {
    text-overflow: ellipsis;
}
