.sort {
  height: 16px;
  width: 16px;
}

.icon {
  font-size: 16px !important;
  height: 16px !important;
  height: 16px !important;
}
