.container {
    align-items: center;
    background-color: var(--yellow);
    border-radius: 4px;
    display: flex;
    left: calc(50% - 250px);
    padding: 16px;
    position: fixed;
    bottom: 24px;
    align-self: center;
    transform: translate(-50%, 0);
    width: 500px;
    z-index: var(--zi-sync-bar);
}

.text {
    margin: 0 32px 0 16px;
}

.title {
    font-weight: var(--fw-bold);
    font-size: 14px;
    margin-bottom: 8px;
}

.button {
    white-space: nowrap;
}

.saveInfo {
    font-size: 12px;
}
