.container {
    background-color: var(--light-red);
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-bottom: 32px;
    width: var(--sidebar-width);
}

.head {
    background-image: url('assets/images/visuals/scribble-purple.png');
    background-size: 54px 46px;
    background-repeat: no-repeat;
    background-position: 0 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: var(--header-height);
    flex-shrink: 0;
}

.logo {
    width: 64px;
}

.logo img {
    max-width: 100%;
}

.navigation {
    flex-grow: 1;
    padding-top: 48px;
}

.list {
    margin-bottom: 24px !important;
    padding: 0 8px !important;
}

.subheader {
    color: var(--green-dark-faded);
    text-transform: uppercase;
    font-weight: var(--fw-black) !important;
    font-size: 10px !important;
    letter-spacing: 0.5px;
}

.user {
    margin: 0 auto;
    flex-shrink: 0;
}

.userAvatarButton {
    border-radius: 50%;
    border: 0;
    display: block im !important;
    box-shadow: rgb(0 0 0 / 20%) 0px 2px 1px -1px,
        rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px;
    padding: 0;
    transition: transform 0.3s ease-in-out;
    outline: 0;
}

.userAvatarButton:hover {
    transform: scale(1.2);
}

.link {
    color: var(--green-dark);
    margin-bottom: 16px;
    opacity: 0.7;
    transition: opacity 0.3s ease-in-out;
}

.link:hover {
    opacity: 1;
}

.linkActive {
    background-color: rgba(var(--green-dark-rgb), 0.04) !important;
    border-radius: 8px;
    color: var(--purple);
    opacity: 1;
}

.linkIcon {
    min-width: 0 !important;
}

.linkText {
    padding-left: 16px;
}

.linkTextBody {
    font-size: 14px !important;
}

@media (max-width: 1300px) {
    .logo {
        width: 48px;
    }
    .subheader {
        display: none;
    }

    .list {
        margin-bottom: 40px !important;
    }

    .linkText {
        display: none;
    }
}
