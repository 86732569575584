.blocks {
    display: flex;
}

.block + .block {
    margin-left: 32px;
}

.block {
    background-color: var(--white);
    border-radius: 8px;
    padding: 24px;
    width: 400px;
}

.type {
    font-size: 12px;
    font-weight: var(--fw-bold);
    margin-bottom: 16px;
    text-transform: uppercase;
}

.propertyTitle {
    text-transform: uppercase;
    font-size: 10px;
    font-weight: var(--fw-bold);
    opacity: 0.4;
    letter-spacing: 0.4px;
}

.propertyValue {
    margin-top: 4px;
}

.propertyValueEmpty {
    opacity: 0.8;
}

.property + .property {
    margin-top: 32px;
}

.propertyList {
    padding: 0;
    margin: 0;
    list-style: none;
}

.propertyListItem + .propertyListItem {
    margin-top: 8px;
}

.propertyListItemTitle {
    font-size: 14px;
    font-weight: var(--fw-bold);
}

.propertyListItemText {
    display: block;
}

.image {
    padding-top: 100%;
    position: relative;
    width: 100%;
}

.imageInner {
    background-size: contain;
    background-position: center center;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}
