.mainContainer {
  align-items: center;
  background-color: var(--off-white);
  justify-content: center;
  display: flex;
  height: 100%;
  flex-direction: row;
  text-align: center;
  width: 100%;
}

.loginSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 300px;
}

.logo {
  width: 64px;
}

.logo img {
  display: block;
  max-width: 100%;
}

.collage {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 2;
}

.scribble {
  position: absolute;
  top: 24px;
  left: 0;
  width: 64px;
  z-index: 2;
}

.ferlyLogo {
  position: absolute;
  left: 32px;
  bottom: 32px;
  z-index: 2;
}

.ferlyDescription {
  margin: 32px 0;
}
