.mainContainer {
    padding-bottom: 80px;
    padding-top: 40px;
}

/* Block */
.blockDisabled {
    opacity: 0.5;
}

.block {
    margin-bottom: 88px;
}

.block:not(:first-of-type) {
    margin-top: 48px;
}

.blockTitle {
    font-size: 14px;
    font-weight: var(--fw-black);
    margin-bottom: 8px;
    text-transform: uppercase;
    letter-spacing: 0.4px;
}

.blockSwitch {
    margin-left: 16px;
}

.blockDescription {
    margin-bottom: 32px;
}

.blockContent {
    margin-top: 48px;
}

.blockInputRow {
    display: flex;
    margin-left: -16px;
    margin-right: -16px;
}

.blockInputRowGroup {
    min-width: 0;
    width: 250px;
}

.blockInputRowGroup {
    margin: 0 16px;
}

.formControl {
    margin: 16px !important;
    width: 250px;
}

.formControlMedia {
    display: inline-flex;
    width: 330px;
}

.formControlLG {
    width: 350px;
}

.formControlXL {
    width: 532px;
}

.formControlHigh {
    min-height: 64px !important;
}

.formControlDate :global(.MuiIconButton-root) {
    color: var(--green-dark) !important;
}

.inputIcon {
    color: var(--green-dark) !important;
}

.tagChips {
    display: flex;
    flex-wrap: wrap;
}

.tagChip {
    margin: 2px;
}

.emptyRow {
    opacity: 0.5;
    text-align: center;
    width: 100%;
}
