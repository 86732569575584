.container {
    --sidebar-width: 250px;
    display: flex;
}

.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    width: var(--sidebar-width);
    bottom: 0;
    padding: 40px;
    padding-right: 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
}

.sidebarContent {
    flex: 1;
}
.sidebarInputs {
    margin-top: 32px;
}

.sidebarInputGroup {
    border-bottom: 1px solid rgba(var(--green-dark-rgb), 0.1);
    padding-bottom: 32px;
}

.sidebarInputGroup + .sidebarInputGroup {
    padding-top: 32px;
}

.sidebarInputGroup:last-child {
    border: 0;
}
.sidebarInput + .sidebarInput {
    margin-top: 8px;
}

.stretchedInput {
    width: 100% !important;
}

.inputIcon {
    color: var(--green-dark) !important;
}

.fab {
    position: fixed !important;
    right: 40px;
    top: 40px;
    z-index: 1;
}

.title {
    font-size: 20px;
    font-weight: var(--fw-black);
    flex: 1;
}

.selectedActivitiesCounter {
    margin-top: 8px;
    font-weight: var(--fw-bold);
    font-size: 14px;
}

.image {
    display: block;
    max-width: 100%;
}

.sessions {
    width: 100%;
    padding: 40px;
    padding-left: calc(var(--sidebar-width) + 40px);
}

.sessionButton {
    background-color: rgba(var(--green-dark-rgb), 0.02) !important;
    border: 1px solid rgba(0, 0, 0, 0.23) !important;
    border-radius: 6px !important;
    opacity: 0.6;
    position: relative;
    padding: 16px;
    border: 1px solid;
    width: 100% !important;
}

.sessionButton:hover {
    border-color: rgba(0, 0, 0, 0.87) !important;
}

.sessionButtonActive {
    background-color: var(--white-off) !important;
    border-color: var(--purple) !important;
    /* border-width: 2px !important; */
    opacity: 1;
}

.sessionButton::after {
    content: '';
    display: block;
    padding-bottom: 100%;
}

.sessionTitle {
    font-size: 14px !important;
    font-family: var(--font);

    margin-top: 16px;
    text-align: left;
}

.session {
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 16px;
}

.sessionCheck {
    position: absolute;
}

.thumbnail {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    flex: 1;
    width: 100%;
}

.thumbnailBackground {
    background-color: var(--purple);
    border-radius: 4px;
    height: 100%;
    width: 100%;
}

.thumbnailImage {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
}

.formControlHigh {
    min-height: 64px !important;
}

.inputIcon {
    color: var(--green-dark) !important;
    opacity: 0.3;
}

.tagChips {
    display: flex;
    flex-wrap: wrap;
}

.tagChip {
    margin: 2px;
}

.buttonClear {
    margin-bottom: 16px !important;
}
