.module {
    margin-bottom: 32px;
}

.module + .module {
    border-top: 1px dashed rgba(var(--green-dark-rgb), 0.1);
    padding-top: 32px;
}

.moduleTitle {
    display: flex;
    align-items: center;
    font-weight: var(--fw-bold);
    font-size: 14px;
}

.lockIcon {
    font-size: 16px !important;
    margin-right: 4px !important;
}

.moduleInfo {
    display: flex;
    margin-top: 4px;
    opacity: 0.5;
}

.moduleInfoItem + .moduleInfoItem {
    margin-left: 16px;
}

.moduleInfoItem {
    font-size: 14px;
}

.moduleDescription {
    margin-top: 16px;
    max-width: 640px;
}

.sessions {
    display: flex;
    overflow: scroll;
    padding-bottom: 8px;
}

.sessionsWrapper {
    margin-top: 40px;
    position: relative;
}

.session {
    background-color: var(--white);
    padding: 24px;
    border-radius: 8px;
}

.session + .session {
    margin-left: 24px;
}

.sessionsGradient {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    width: 48px;
    background: rgb(var(--off-white-rgb));
    background: linear-gradient(
        90deg,
        rgba(var(--off-white-rgb), 0) 0%,
        rgba(var(--off-white-rgb), 1) 100%
    );
    bottom: 0;
    z-index: 1;
}

.danger {
    color: var(--error-color);
    font-style: italic;
}

.sessionNumber {
    font-size: 12px;
    font-weight: var(--fw-bold);
    text-transform: uppercase;
}

.sessionTitle {
    font-weight: var(--fw-bold);
    margin-top: 4px;
    font-size: 14px;
}

.sessionHead {
    margin-bottom: 24px;
}

.property + .property {
    margin-top: 24px;
}

.propertyTitle {
    text-transform: uppercase;
    font-size: 10px;
    font-weight: var(--fw-bold);
    opacity: 0.4;
    letter-spacing: 0.4px;
}

.propertyValue {
    margin-top: 4px;
    font-size: 14px;
    width: 180px;
}
.empty {
    align-items: center;
    display: inline-flex;
    padding: 24px;
    border-radius: 6px;
    background-color: var(--main-pink-color);
}

.emptyText {
    margin-left: 8px;
}
