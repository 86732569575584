@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&display=swap');

:root {
    --green: #d6ffab;
    --green-dark: #363d2b;
    --green-dark-rgb: 54, 61, 43;
    --off-white: #fff7f2;
    --off-white-rgb: 255, 247, 242;
    --main-pink-color: #ffd5d1;
    --main-blue-color: #dee0f2;
    --dark-red: #f44336;
    --light-red: #ffeae8;
    --purple: #6369bd;
    --pink-dark: #f6c0bb;
    --yellow: #ffedab;
    --main-text-color: var(--green-dark);
    --main-bg-color: var(--off-white);
    --light-border-color: rgba(36, 35, 35, 0.87);
    --green-dark-faded: rgba(54, 61, 43, 0.6);

    --bg-food-for-thought: #ffd5d1;
    --bg-guided-practices: #ecedff;
    --bg-podcast: #f8f8fe;
    --bg-story: #fffaf9;

    --error-color: #f44336;

    --white: #ffffff;
    --gray-light: #e8e8e8;
    --gray: #c2c2c2;
    --gray-dark: #73738e;

    --sidebar-width: 250px;
    --header-height: 64px;

    --font: 'Lato', sans-serif;

    --fw-regular: 400;
    --fw-bold: 700;
    --fw-black: 900;

    --zi-header: 10;
    --zi-sync-bar: 15;
    --zi-loader: 20;
}

@media (max-width: 1300px) {
    :root {
        --sidebar-width: 68px;
    }
}

html {
    height: 100%;
}

body {
    height: 100%;
    margin: 0;
    background-color: var(--main-bg-color);
    color: var(--main-text-color);
    font-family: var(--font);

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

#root {
    height: 100%;
}

.icon_primary {
    color: var(--purple) !important;
}

.icon_secondary {
    color: var(--green-dark) !important;
}

body * {
    box-sizing: border-box;
}
