.wrapper {
    display: flex;
    margin-bottom: 24px;
}

.wrapper:first-of-type {
    margin-top: 24px;
}
.container {
    flex: 1;
    /* border-color: rgba(0, 0, 0, 0.23); */
    /* border-style: solid; */
    /* border-width: 1px; */
    /* border-radius: 4px; */
    /* padding: 8px 0; */
}

.blocks {
    padding-bottom: 32px;
}

.addBlockContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.addBlockContainerEmpty {
    border: 1px dashed rgba(var(--green-dark-rgb), 0.2);
    border-radius: 8px;
    padding: 32px;
}

.addBlockContainerError {
    border-color: var(--error-color);
}

.addBlockTitle {
    opacity: 0.5;
    margin-bottom: 24px;
}

.addBlockError {
    margin-top: 24px;
    color: var(--error-color);
}
