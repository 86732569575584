/* Table */
.table {
    border: none;
    border-collapse: collapse;
    height: 100%;
    width: 100%;
}

.row {
    height: 100%;
    width: 100%;
}

.row:hover .cellLink {
    background-color: rgba(var(--green-dark-rgb), 0.05);
}

.rowDeprecated {
    opacity: 0.5;
}

.th {
    text-align: left;
    text-transform: uppercase;
    font-weight: var(--fw-black);
    font-size: 10px;
    letter-spacing: 0.2px;
    opacity: 0.6;
    padding-bottom: 8px;
}

.cell {
    font-size: 14px;
    height: 100%;
    border-bottom: 1px solid rgba(var(--green-dark-rgb), 0.1);
}

.cell:first-child .cellLink {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    padding-left: 24px;
}

.cell:last-child .cellLink {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    padding-right: 24px;
}

.cellLink {
    color: var(--green-dark);
    align-items: center;
    display: flex;
    height: 100%;
    padding-left: 8px;
    padding-bottom: 16px;
    padding-top: 16px;
    text-decoration: none;
    width: 100%;
}

.titleCell {
    display: flex;
    align-items: center;
}

.thumbCell {
    position: relative;
}

.thumbImage {
    display: block;
    height: 80px;
    width: 80px;
}

.noImage {
    align-items: center;
    justify-content: center;
    display: flex;
    opacity: 0.3;
    height: 80px;
    width: 80px;
}

.titleCellText {
    font-weight: var(--fw-bold);
}

.status {
    position: absolute;
    bottom: 0;
    /* transform: translateY(50%); */
}
