.container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.image {
    height: 64px;
}
