.grid {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
}

.gridItem {
    width: calc(12.5% - 14px);
}

.addButton {
    border: 1px solid rgba(0, 0, 0, 0.23) !important;
    border-radius: 6px !important;
    height: 100%;
}

.addButton:hover {
    border-color: rgba(0, 0, 0, 0.87) !important;
}

.session {
    padding-top: 100%;
    width: 100% !important;
}
