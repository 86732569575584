.container {
    background-color: var(--white);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    padding: 32px 24px;
    height: 100%;
}

.inputIcon {
    color: var(--green-dark) !important;
}

.inputRow {
    width: 200px;
}

.row {
    display: flex;
}

.row + .row {
    margin-top: 24px;
}

.rowStretched {
    flex-grow: 1;
}

.menuItemContent {
    text-transform: capitalize;
}

.buttonRow {
    justify-content: space-between;
}
