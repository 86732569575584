.wrapper {
    display: flex;
    margin-bottom: 48px;
}

.wrapper:first-of-type {
    margin-top: 24px;
}
.container {
    background-color: var(--white);
    border-radius: 8px;
    flex: 1;
    padding: 0 24px;
}

/* Arrows */
.arrows {
    display: flex;
    flex-direction: column;
    margin-left: 24px;
    margin-top: 64px;
}

.arrowUp {
    margin-bottom: 8px;
}

/* Header */
.header {
    font-size: 10px;
    display: flex;
    align-items: center;
    letter-spacing: 0.4%;
    font-weight: var(--fw-bold);
    padding: 24px 0;
    border-bottom: 1px solid rgba(var(--green-dark-rgb), 0.1);
}

.headerLeft {
    flex: 1;
}

.typeText {
    text-transform: uppercase;
}

.idText {
    margin-top: 4px;
    opacity: 0.3;
}

/* Body */
.body {
    padding: 24px 0;
}

/* Footer */
.footer {
    border-top: 1px solid rgba(var(--green-dark-rgb), 0.1);
    display: flex;
    align-items: center;
    padding: 24px 0;
}

.footer .formControl {
    margin: 0 16px;
}

/* Input */
.inputGroup + .inputGroup {
    margin-top: 32px;
}

.inputGroupTitle {
    font-size: 12px;
    font-weight: var(--fw-bold);
    text-transform: uppercase;
    letter-spacing: 0.4px;
    margin-bottom: 8px;
}
.inputRow {
    display: flex;
    margin-left: -16px;
    margin-right: -16px;
}

.formControl {
    margin: 16px;
    width: 250px;
}

.formControlLG {
    width: 350px;
}

.formControlXL {
    width: 532px;
}

.formInput {
    width: 100% !important;
}

.formControlDate :global(.MuiIconButton-root) {
    color: var(--green-dark) !important;
}
