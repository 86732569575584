.tagsList {
    --tagOffset: 8px;
    display: flex;
    flex-wrap: wrap;
}

.chip {
    margin-bottom: 0;
    margin-top: calc(var(--tagOffset) * 2) !important;
    margin-right: var(--tagOffset) !important;
}
