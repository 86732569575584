@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&display=swap);
:root {
    --green: #d6ffab;
    --green-dark: #363d2b;
    --green-dark-rgb: 54, 61, 43;
    --off-white: #fff7f2;
    --off-white-rgb: 255, 247, 242;
    --main-pink-color: #ffd5d1;
    --main-blue-color: #dee0f2;
    --dark-red: #f44336;
    --light-red: #ffeae8;
    --purple: #6369bd;
    --pink-dark: #f6c0bb;
    --yellow: #ffedab;
    --main-text-color: var(--green-dark);
    --main-bg-color: var(--off-white);
    --light-border-color: rgba(36, 35, 35, 0.87);
    --green-dark-faded: rgba(54, 61, 43, 0.6);

    --bg-food-for-thought: #ffd5d1;
    --bg-guided-practices: #ecedff;
    --bg-podcast: #f8f8fe;
    --bg-story: #fffaf9;

    --error-color: #f44336;

    --white: #ffffff;
    --gray-light: #e8e8e8;
    --gray: #c2c2c2;
    --gray-dark: #73738e;

    --sidebar-width: 250px;
    --header-height: 64px;

    --font: 'Lato', sans-serif;

    --fw-regular: 400;
    --fw-bold: 700;
    --fw-black: 900;

    --zi-header: 10;
    --zi-sync-bar: 15;
    --zi-loader: 20;
}

@media (max-width: 1300px) {
    :root {
        --sidebar-width: 68px;
    }
}

html {
    height: 100%;
}

body {
    height: 100%;
    margin: 0;
    background-color: #fff7f2;
    background-color: var(--main-bg-color);
    color: #363d2b;
    color: var(--main-text-color);
    font-family: 'Lato', sans-serif;
    font-family: var(--font);

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

#root {
    height: 100%;
}

.icon_primary {
    color: #6369bd !important;
    color: var(--purple) !important;
}

.icon_secondary {
    color: #363d2b !important;
    color: var(--green-dark) !important;
}

body * {
    box-sizing: border-box;
}

.loader_container__oUJCN {
    -webkit-align-items: center;
            align-items: center;
    background-color: var(--main-bg-color);
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex: 1 1;
            flex: 1 1;
    height: 100%;
}

.loader_containerFixed__1mMjL {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: auto;
    background-color: rgba(var(--off-white-rgb), 0.3);
    z-index: var(--zi-loader);
}

.status-chip_container__3aXs- {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}
.status-chip_chip_draft__wYYzO {
    background-color: var(--main-blue-color) !important;
}

.status-chip_chip_published__2TAfH {
    background-color: var(--green) !important;
}

.status-chip_chip_not_synced__3mRvp {
    background-color: var(--yellow) !important;
}

.status-chip_chip_unpublished__2P2Qy {
    background-color: var(--pink-dark) !important;
}

.status-chip_label__1eDih {
    font-size: 10px !important;
    margin-top: 2px;

    letter-spacing: 0.4px;
    text-transform: uppercase;
}

.status-chip_minimalChip__ZWFS_ {
    --size: 16px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
    height: var(--size);
    width: var(--size);
    border-radius: calc(var(--size) / 2);
}

.status-chip_minimalContainer__wMbR1 {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.status-chip_warningIcon__7Oj3a {
    color: var(--dark-red) !important;
}

.status-chip_container__3aXs- .status-chip_warningIcon__7Oj3a {
    margin-right: 4px;
}

.table_row__2Y-yD {
    transition: background-color 0.3s ease-in-out;
}
.table_row__2Y-yD:hover {
    background-color: rgba(var(--green-dark-rgb), 0.05);
    cursor: pointer;
}
.table_mainTable__2xCz8 {
    margin-top: 24px;
    width: 100%;
}
.table_thumbnail__3RWZt {
    display: block;
    max-width: 100%;
}
.table_thumbnailWrapper__1TEYq {
    height: 64px;
    width: 64px;
    display: block;
    position: relative;
}

.table_noImage__Im1OQ {
    display: -webkit-flex;
    display: flex;
    height: 64px;
    width: 64px;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    opacity: 0.3;
}

.table_status__3cY7U {
    position: absolute;
    bottom: 0;
    -webkit-transform: translateY(50%);
            transform: translateY(50%);
}

.table_showUnpublished__GhpSm {
    padding-left: 2%;
}

.table_cell__3p2uX {
    font-size: 14px;
    padding-top: 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid rgba(var(--green-dark-rgb), 0.1);
    /* white-space: nowrap; */
}

.table_cell__3p2uX:first-child {
    padding-left: 40px;
}

.table_cell__3p2uX:last-child {
    padding-right: 40px;
}

.table_cellBold__s4sAT {
    font-weight: var(--fw-bold);
    max-width: 160px;
}
.table_columnTitle__1Daoi {
    opacity: 0.6;
    letter-spacing: 0.2px;
    -webkit-align-items: center;
            align-items: center;
    display: -webkit-flex;
    display: flex;
    text-transform: uppercase;
    font-size: 10px;
}

.table_search__3_pt5 {
    margin-left: 40px;
    margin-bottom: 24px;
}

.sorting-indicator_sort__1aviz {
  height: 16px;
  width: 16px;
}

.sorting-indicator_icon__3W9BT {
  font-size: 16px !important;
  height: 16px !important;
  height: 16px !important;
}

.global-filter_searchBar__1qdmC {
  width: 300px;
}

.global-filter_searchIcon__2NT6P {
  color: var(--green-dark-faded) !important;
}

.snackbar_caption__8_mfQ {
    font-weight: var(--fw-bold);
    font-size: 12px;
}

.header_header__v9FKw {
    background-color: var(--off-white);
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    position: fixed;
    left: var(--sidebar-width);
    right: 0;
    top: 0;
    height: var(--header-height);
    padding: 0 40px;
    z-index: var(--zi-header);
}

.header_left__35XJZ {
    -webkit-align-items: center;
            align-items: center;
    display: -webkit-flex;
    display: flex;
    position: relative;
}

.header_backButton__ppLtr {
    position: absolute !important;
    left: 0;
    top: 50%;
    -webkit-transform: translate(-100%, -50%);
            transform: translate(-100%, -50%);
    padding: 8px !important;
}

.header_backButtonIcon__3cAeM {
    font-size: 20px !important;
}

.header_headerTitle__bkugv {
    font-size: 18px;
    font-weight: var(--fw-bold);
    margin: 0;
}

.header_titleInput__JSAJB input {
    font-size: 18px;
    font-weight: var(--fw-bold);
}

.header_titleAdornment__ocBol {
    margin-left: 24px;
}

.page_content__29-Tu {
    padding-top: var(--header-height);
}

.page_contentPaddingLeft__3gGo8 {
    padding-left: 40px;
}

.page_contentPaddingRight__ef7U6 {
    padding-right: 40px;
}

.page_fab__33Jab {
    position: fixed !important;
    right: 40px;
    bottom: 40px;
}

.page_backdrop__2dvDT {
    background-color: rgba(var(--off-white-rgb), 0.8) !important;
    z-index: var(--zi-loader) !important;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.page_loadingText__1CBPT {
    font-size: 12px;
    margin-top: 16px;
    font-weight: var(--fw-bold);
}

.file-upload_container__2lt5p {
    box-sizing: border-box;
    position: relative;
    height: 200px;
    width: 100%;
}

.file-upload_container__2lt5p * {
    box-sizing: border-box;
}

.file-upload_fieldset__2nu6Y {
    padding: 0 8px;
    margin: 0;
    position: absolute;
    top: -5px;
    overflow: hidden;
    left: 0;
    right: 0;
    bottom: 0;
    font-size: 1rem;
    border-color: rgba(0, 0, 0, 0.23);
    border-width: 1px;
    border-radius: 4px;
    pointer-events: none;
}

.file-upload_wrappingLabel__wBi1w {
    cursor: pointer;
}

.file-upload_wrappingLabel__wBi1w:hover .file-upload_fieldset__2nu6Y {
    border-color: rgba(0, 0, 0, 0.87);
}

.file-upload_containerError__3Nv5p .file-upload_fieldset__2nu6Y {
    border-color: var(--error-color) !important;
}

.file-upload_legend__37KEE {
    display: block;
    max-width: 1000px;
    padding: 0;
    text-align: left;
    height: 11px;
    font-size: 0.75em;
    /* transform: scale(0.75); */
    width: auto;
}

.file-upload_legend__37KEE span {
    display: inline-block;
    padding-left: 5px;
    padding-right: 5px;
    visibility: hidden;
}

.file-upload_input__1J8U1 {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    height: 100%;
    width: 100%;
}

.file-upload_label__Bd_Cx {
    position: absolute;
    top: 0;
    left: 0;
    -webkit-transform: translate(14px, -6px) scale(0.75);
            transform: translate(14px, -6px) scale(0.75);
    color: rgba(0, 0, 0, 0.54);
    line-height: 1;
    -webkit-transform-origin: top left;
            transform-origin: top left;
    z-index: 2;
}

.file-upload_containerError__3Nv5p .file-upload_label__Bd_Cx {
    color: var(--error-color);
}

.file-upload_metadata__LErCN {
    margin-bottom: 24px;
}

.file-upload_metadataRow__1jTcH {
    display: -webkit-flex;
    display: flex;
}

.file-upload_metadataText__hJpOS + .file-upload_metadataText__hJpOS {
    margin-left: 8px;
}

.file-upload_metadataText__hJpOS {
    text-transform: uppercase;
    opacity: 0.7;
    letter-spacing: 0.4px;
    font-size: 12px;
    margin-top: 4px;
}

.file-upload_metadataTextBold__9o76m {
    font-weight: var(--fw-bold);
}

.file-upload_typeIcon__18np9 {
    opacity: 0.6;
    margin-bottom: 4px !important;
}

.file-upload_inputWithImage__RxXRw {
    /* padding: 16px !important; */
}

.file-upload_input__1J8U1 {
    padding: 24px;
}

.file-upload_image__10uBZ {
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
}

.file-upload_audio__yyGTI {
    position: absolute;
    bottom: 16px;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
    z-index: 10;
}

.file-upload_editOverlay__36YUU {
    background-color: rgba(var(--off-white-rgb), 0.8);
    border-radius: 4px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    position: absolute;
    top: 1px;
    left: 1px;
    right: 1px;
    bottom: 1px;
    z-index: 1;
    opacity: 0;
}

.file-upload_inputWithValue__2DXoN:hover .file-upload_editOverlay__36YUU {
    opacity: 1;
}

.sync-bar_container__3_qr9 {
    -webkit-align-items: center;
            align-items: center;
    background-color: var(--yellow);
    border-radius: 4px;
    display: -webkit-flex;
    display: flex;
    left: calc(50% - 250px);
    padding: 16px;
    position: fixed;
    bottom: 24px;
    -webkit-align-self: center;
            align-self: center;
    -webkit-transform: translate(-50%, 0);
            transform: translate(-50%, 0);
    width: 500px;
    z-index: var(--zi-sync-bar);
}

.sync-bar_text__3bqUH {
    margin: 0 32px 0 16px;
}

.sync-bar_title__2qUf8 {
    font-weight: var(--fw-bold);
    font-size: 14px;
    margin-bottom: 8px;
}

.sync-bar_button__3Habj {
    white-space: nowrap;
}

.sync-bar_saveInfo__32ya0 {
    font-size: 12px;
}

.activity_mainContainer__2xi1W {
    padding-bottom: 80px;
    padding-top: 40px;
}

/* Block */
.activity_blockDisabled__37ScP {
    opacity: 0.5;
}

.activity_block__1KA-X {
    margin-bottom: 88px;
}

.activity_block__1KA-X:not(:first-of-type) {
    margin-top: 48px;
}

.activity_blockTitle__12me0 {
    font-size: 14px;
    font-weight: var(--fw-black);
    margin-bottom: 8px;
    text-transform: uppercase;
    letter-spacing: 0.4px;
}

.activity_blockSwitch__2aJQ8 {
    margin-left: 16px;
}

.activity_blockDescription__3iwss {
    margin-bottom: 32px;
}

.activity_blockContent__rAFa3 {
    margin-top: 48px;
}

.activity_blockInputRow__1-ned {
    display: -webkit-flex;
    display: flex;
    margin-left: -16px;
    margin-right: -16px;
}

.activity_blockInputRowGroup__1G9pX {
    min-width: 0;
    width: 250px;
}

.activity_blockInputRowGroup__1G9pX {
    margin: 0 16px;
}

.activity_formControl__28uOe {
    margin: 16px !important;
    width: 250px;
}

.activity_formControlMedia__2Bo3n {
    display: -webkit-inline-flex;
    display: inline-flex;
    width: 330px;
}

.activity_formControlLG__2HsDO {
    width: 350px;
}

.activity_formControlXL__2gpk9 {
    width: 532px;
}

.activity_formControlHigh__1LIA2 {
    min-height: 64px !important;
}

.activity_formControlDate__lTM9u .MuiIconButton-root {
    color: var(--green-dark) !important;
}

.activity_inputIcon__2J-0M {
    color: var(--green-dark) !important;
}

.activity_tagChips__Zs9nv {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}

.activity_tagChip__2HwgH {
    margin: 2px;
}

.activity_emptyRow__1DWU6 {
    opacity: 0.5;
    text-align: center;
    width: 100%;
}

.block_wrapper__36wKB {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
}

.block_wrapper__36wKB:hover .block_container__nMPEw {
    /* background-color: white; */
}
.block_container__nMPEw {
    padding: 8px 16px;
    border: 1px dashed rgba(var(--green-dark-rgb), 0.2);
    border-radius: 8px;
    background-color: var(--off-white);
    display: -webkit-flex;
    display: flex;
    -webkit-flex: 1 1;
            flex: 1 1;
    -webkit-flex-direction: row;
            flex-direction: row;
    margin-left: 8px;
}

.block_wrapper__36wKB + .block_wrapper__36wKB {
    margin-top: 8px;
}

.block_content__3zzsr {
    -webkit-flex: 1 1;
            flex: 1 1;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    height: 100%;
}

.block_header__lVluM {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
}

.block_type__2-gD3 {
    text-transform: uppercase;
    font-size: 10px;
    font-weight: var(--fw-bold);
    opacity: 0.4;
    letter-spacing: 0.4px;
    -webkit-flex: 1 1;
            flex: 1 1;
    /* margin-bottom: 8px; */
}

/* Drag */
.block_dragHandle__2MAcN {
    padding-top: 16px;
    cursor: move;
}

.block_dragHandleIcon__AlTMm {
    color: var(--gray);
}

/* 
 * Previews
 */
.block_preview__3lxXD {
    width: 100%;
}
.block_previewDivider__3rkCa {
    height: 2px;
    background-color: var(--green-dark);
    width: 90%;
}

.block_previewImage__1Fn_r {
    padding-right: 16px;
}

.block_previewCallout__2mWm5 {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
}

.block_previewCalloutIcon__1LMC7 {
    opacity: 0.8;
}

.block_previewCalloutMarkdown__1MBst {
    margin-left: 16px;
    color: var(--purple);
}

.block_previewText__3xMa6 {
    font-size: 14px;
}

.block_previewTitle__35lhU {
    font-size: 20px;
    font-weight: var(--fw-bold);
}

.transcipt-preview-image_container__7qz1e {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.transcipt-preview-image_image__3DzTO {
    height: 64px;
}

.modal_component__3xGLM {
    margin-top: 16px;
}

.transcript-editor-dialog_header__3nzXw {
    background-color: var(--white);
    -webkit-align-items: center;
            align-items: center;
    display: -webkit-flex;
    display: flex;
    height: var(--header-height);
    padding: 0 16px;
}

.transcript-editor-dialog_headerText__3dD0n {
    -webkit-flex: 1 1;
            flex: 1 1;
}

.transcript-editor-dialog_headerTitle__2kle5 {
    /* color: var(--white); */
    font-size: 12px;
    font-weight: var(--fw-bold);
    letter-spacing: 0.4px;
    text-transform: uppercase;
}

.transcript-editor-dialog_editorWrapper__31uPJ {
    min-height: 400px;
}

.transcript-editor-dialog_editor__1N9C_ {
    padding: 0 24px;
}

.transcript-editor-dialog_textareaWrapper__1F_Ys {
    padding: 24px;
}

.transcript-image-picker_container__1Ydhh {
    padding: 24px;
}

.transcript-image-picker_button__2oiDD {
    display: block;
    background-color: rgba(var(--green-dark-rgb), 0.1) !important;
    border: 1px solid rgba(0, 0, 0, 0.23) !important;
    border-radius: 6px !important;
    opacity: 0.6;
    position: relative;
    padding: 16px 0;
    border: 1px solid;
    width: 100%;
}

.transcript-image-picker_button__2oiDD:hover {
    border-color: rgba(0, 0, 0, 0.87) !important;
}

.transcript-image-picker_buttonActive__2Fc9S {
    background-color: var(--white-off) !important;
    border-color: var(--purple) !important;
    /* border-width: 2px !important; */
    opacity: 1;
}
.transcript-image-picker_button__2oiDD + .transcript-image-picker_button__2oiDD {
    margin-top: 16px;
}

.transcript-image-picker_buttonCheck__tzM4Y {
    position: absolute;
    top: 8px;
    right: 8px;
}

.transcript-preview_wrapper__1IcJr {
    display: -webkit-flex;
    display: flex;
    margin-bottom: 24px;
}

.transcript-preview_wrapper__1IcJr:first-of-type {
    margin-top: 24px;
}
.transcript-preview_container__3Jihd {
    -webkit-flex: 1 1;
            flex: 1 1;
    /* border-color: rgba(0, 0, 0, 0.23); */
    /* border-style: solid; */
    /* border-width: 1px; */
    /* border-radius: 4px; */
    /* padding: 8px 0; */
}

.transcript-preview_blocks__2sCgV {
    padding-bottom: 32px;
}

.transcript-preview_addBlockContainer__22t-3 {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
}

.transcript-preview_addBlockContainerEmpty__2wUip {
    border: 1px dashed rgba(var(--green-dark-rgb), 0.2);
    border-radius: 8px;
    padding: 32px;
}

.transcript-preview_addBlockContainerError__UWx5o {
    border-color: var(--error-color);
}

.transcript-preview_addBlockTitle__oLY8Y {
    opacity: 0.5;
    margin-bottom: 24px;
}

.transcript-preview_addBlockError__1dDQW {
    margin-top: 24px;
    color: var(--error-color);
}

.activities-picker-dialog_container__2TOkj {
    --sidebar-width: 250px;
    display: -webkit-flex;
    display: flex;
}

.activities-picker-dialog_sidebar__3QlSc {
    position: fixed;
    top: 0;
    left: 0;
    width: var(--sidebar-width);
    bottom: 0;
    padding: 40px;
    padding-right: 0;
    z-index: 1;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.activities-picker-dialog_sidebarContent__2hrft {
    -webkit-flex: 1 1;
            flex: 1 1;
}
.activities-picker-dialog_sidebarInputs__1YTnz {
    margin-top: 32px;
}

.activities-picker-dialog_sidebarInputGroup__3ilK8 {
    border-bottom: 1px solid rgba(var(--green-dark-rgb), 0.1);
    padding-bottom: 32px;
}

.activities-picker-dialog_sidebarInputGroup__3ilK8 + .activities-picker-dialog_sidebarInputGroup__3ilK8 {
    padding-top: 32px;
}

.activities-picker-dialog_sidebarInputGroup__3ilK8:last-child {
    border: 0;
}
.activities-picker-dialog_sidebarInput__otk3J + .activities-picker-dialog_sidebarInput__otk3J {
    margin-top: 8px;
}

.activities-picker-dialog_stretchedInput__jgGSY {
    width: 100% !important;
}

.activities-picker-dialog_inputIcon__2gUK1 {
    color: var(--green-dark) !important;
}

.activities-picker-dialog_fab__1LSoM {
    position: fixed !important;
    right: 40px;
    top: 40px;
    z-index: 1;
}

.activities-picker-dialog_title__Q4UM6 {
    font-size: 20px;
    font-weight: var(--fw-black);
    -webkit-flex: 1 1;
            flex: 1 1;
}

.activities-picker-dialog_selectedActivitiesCounter__2PUME {
    margin-top: 8px;
    font-weight: var(--fw-bold);
    font-size: 14px;
}

.activities-picker-dialog_image__2migp {
    display: block;
    max-width: 100%;
}

.activities-picker-dialog_sessions__1YtI2 {
    width: 100%;
    padding: 40px;
    padding-left: calc(var(--sidebar-width) + 40px);
}

.activities-picker-dialog_sessionButton__3r212 {
    background-color: rgba(var(--green-dark-rgb), 0.02) !important;
    border: 1px solid rgba(0, 0, 0, 0.23) !important;
    border-radius: 6px !important;
    opacity: 0.6;
    position: relative;
    padding: 16px;
    border: 1px solid;
    width: 100% !important;
}

.activities-picker-dialog_sessionButton__3r212:hover {
    border-color: rgba(0, 0, 0, 0.87) !important;
}

.activities-picker-dialog_sessionButtonActive__Geg7P {
    background-color: var(--white-off) !important;
    border-color: var(--purple) !important;
    /* border-width: 2px !important; */
    opacity: 1;
}

.activities-picker-dialog_sessionButton__3r212::after {
    content: '';
    display: block;
    padding-bottom: 100%;
}

.activities-picker-dialog_sessionTitle__1R4wJ {
    font-size: 14px !important;
    font-family: var(--font);

    margin-top: 16px;
    text-align: left;
}

.activities-picker-dialog_session__zPQss {
    border-radius: 6px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 16px;
}

.activities-picker-dialog_sessionCheck__3oWj2 {
    position: absolute;
}

.activities-picker-dialog_thumbnail__2-X6_ {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    position: relative;
    -webkit-flex: 1 1;
            flex: 1 1;
    width: 100%;
}

.activities-picker-dialog_thumbnailBackground__rjekt {
    background-color: var(--purple);
    border-radius: 4px;
    height: 100%;
    width: 100%;
}

.activities-picker-dialog_thumbnailImage__31DZ6 {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
}

.activities-picker-dialog_formControlHigh__tCEvf {
    min-height: 64px !important;
}

.activities-picker-dialog_inputIcon__2gUK1 {
    color: var(--green-dark) !important;
    opacity: 0.3;
}

.activities-picker-dialog_tagChips__2WqI0 {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}

.activities-picker-dialog_tagChip__3JOcx {
    margin: 2px;
}

.activities-picker-dialog_buttonClear__3PZXz {
    margin-bottom: 16px !important;
}

.program-picker-content_container__P0eYO {
    display: -webkit-flex;
    display: flex;
}

.program-picker-content_containerFullWidth__2cMbP {
    display: block;
}

.program-picker-content_side__2NAuZ {
    width: calc(50% - 16px);
}

.program-picker-content_divider__2PMy5 {
    -webkit-align-self: stretch;
            align-self: stretch;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    width: 32px;
}

.program-picker-content_new__Dolwb {
    margin-top: 16px;
}

.program-picker-content_programs__tYGb_ {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    height: 320px;
    overflow-y: scroll;
    position: relative;
}

.program-picker-content_program__3XEmO {
    margin-bottom: 16px;
}

.program-picker-content_programContent__Ppr3b {
    overflow: hidden;
    white-space: nowrap;
}

.program-picker-content_programTitle__XVuWQ {
    text-overflow: ellipsis;
}

.activities-picker-item_activity__2Kv_P {
    display: block;
    text-align: left;
    color: var(--green-dark);
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
    border-radius: 6px;
    position: relative;
}

.activities-picker-item_activity__2Kv_P::after {
    content: '';
    display: block;
    padding-bottom: 100%;
}

.activities-picker-item_background__1s0mM {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 6px;
    opacity: 0.4;
}

.activities-picker-item_activityContent__26A_s {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 8px;
}

.activities-picker-item_activityThumb__2Qcmp {
    -webkit-flex: 1 1;
            flex: 1 1;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
}

.activities-picker-item_activityTitle__2gZcQ {
    font-size: 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    overflow: hidden;
}

.activities-picker-item_activity__2Kv_P:hover {
    border-color: rgba(0, 0, 0, 0.87) !important;
}

.activities-picker-item_activityWithButtons__1QoXa:hover .activities-picker-item_activityButtons__1Y2pI {
    opacity: 1;
}

.activities-picker-item_activityWithButtons__1QoXa:hover .activities-picker-item_activityThumb__2Qcmp {
    opacity: 0;
}

.activities-picker-item_activityWithButtons__1QoXa:hover .activities-picker-item_activityTitle__2gZcQ {
    opacity: 0;
}

.activities-picker-item_activityWithButtons__1QoXa:hover .activities-picker-item_statusChip__27RTe {
    display: none;
}

.activities-picker-item_activityButtons__1Y2pI {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    opacity: 0;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    /* background-color: rgba(255, 255, 255, 0.8); */
    border-radius: 6px;
    padding: 8px;
}

.activities-picker-item_deleteButton__1dUPu {
    margin: 0 auto !important;
    display: block !important;
}

.activities-picker-item_arrowButtons__3vPZe {
    display: -webkit-flex;
    display: flex;
}

.activities-picker-item_emptyActivity__2vo56 {
    border: 1px solid rgba(0, 0, 0, 0.23) !important;
    width: 100%;
}

.activities-picker-item_statusChip__27RTe {
    position: absolute;
    right: 8px;
    top: 8px;
}

.activities-picker-item_warningIcon__qlMOB {
    position: absolute;
    right: 8px;
    top: 8px;
}

.featured-carousel-item_container__1zyqv {
    background-color: var(--white);
    border-radius: 8px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    padding: 32px 24px;
    height: 100%;
}

.featured-carousel-item_inputIcon__j_Z1p {
    color: var(--green-dark) !important;
}

.featured-carousel-item_inputRow__3zyG6 {
    width: 200px;
}

.featured-carousel-item_row__1jpnG {
    display: -webkit-flex;
    display: flex;
}

.featured-carousel-item_row__1jpnG + .featured-carousel-item_row__1jpnG {
    margin-top: 24px;
}

.featured-carousel-item_rowStretched__2IrQu {
    -webkit-flex-grow: 1;
            flex-grow: 1;
}

.featured-carousel-item_menuItemContent__3AnRN {
    text-transform: capitalize;
}

.featured-carousel-item_buttonRow__3EnMQ {
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.programs-picker-item_program__3oi24 {
    display: block;
    text-align: left;
    color: var(--green-dark);
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
    border-radius: 6px;
    position: relative;
}

.programs-picker-item_program__3oi24::after {
    content: '';
    display: block;
    padding-bottom: 100%;
}

.programs-picker-item_background__3rz18 {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 6px;
    opacity: 0.4;
}

.programs-picker-item_programContent__1rXsi {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 8px;
}

.programs-picker-item_programThumb__3h1kV {
    -webkit-flex: 1 1;
            flex: 1 1;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
}

.programs-picker-item_programTitle__38LBz {
    font-size: 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    overflow: hidden;
}

.programs-picker-item_program__3oi24:hover {
    border-color: rgba(0, 0, 0, 0.87) !important;
}

.programs-picker-item_programWithButtons__ifWNo:hover .programs-picker-item_programButtons__3pAuG {
    opacity: 1;
}

.programs-picker-item_programWithButtons__ifWNo:hover .programs-picker-item_programThumb__3h1kV {
    opacity: 0;
}

.programs-picker-item_programWithButtons__ifWNo:hover .programs-picker-item_programTitle__38LBz {
    opacity: 0;
}

.programs-picker-item_programWithButtons__ifWNo:hover .programs-picker-item_statusChip__1IAll {
    display: none;
}

.programs-picker-item_programButtons__3pAuG {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    opacity: 0;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    /* background-color: rgba(255, 255, 255, 0.8); */
    border-radius: 6px;
    padding: 8px;
}

.programs-picker-item_deleteButton__1l5mX {
    margin: 0 auto !important;
    display: block !important;
}

.programs-picker-item_arrowButtons__3PG9Q {
    display: -webkit-flex;
    display: flex;
}

.programs-picker-item_emptyProgram__38yvC {
    border: 1px solid rgba(0, 0, 0, 0.23) !important;
    width: 100%;
}

.programs-picker-item_statusChip__1IAll {
    position: absolute;
    right: 8px;
    top: 8px;
}

.programs-picker-item_warningIcon__3pKsi {
    position: absolute;
    right: 8px;
    top: 8px;
}

.featured-carousel_container__213wN {
    display: -webkit-flex;
    display: flex;
}

.featured-carousel_item__36Rjk + .featured-carousel_item__36Rjk {
    margin-left: 24px;
}

.featured-carousel_buttonCreate__1_J0R {
    border: 1px solid var(--white) !important;
    background-color: var(--white);
    border-radius: 8px;
    height: 100%;
    padding: 32px 24px;
    width: 100px;
}

.featured-carousel_buttonCreate__1_J0R:hover {
    border: 1px solid rgba(0, 0, 0, 0.23) !important;
}

.activities-picker_container__w2kvS {
    --button-size: 96px;
}

.activities-picker_grid__SJ824 {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    grid-gap: 16px;
    gap: 16px;
}

.activities-picker_gridItem__349L5 {
    width: calc(12.5% - 14px);
}

.activities-picker_addButton__dpVOB {
    border: 1px solid rgba(0, 0, 0, 0.23) !important;
    border-radius: 6px !important;
    /* width: var(--button-size); */
    /* height: var(--button-size); */
}

.activities-picker_addButton__dpVOB:hover {
    border-color: rgba(0, 0, 0, 0.87) !important;
}

.activities-picker_selectedSessions__2QL_d {
    /* display: flex; */
}

.activities-picker_session__mAIRW {
    width: 100% !important;
}

.activities-picker_session__mAIRW::after {
    content: '';
    display: block;
    padding-bottom: 100%;
}

.activities-picker_selectedSession__KxQkb {
    border: 1px solid rgba(0, 0, 0, 0.23) !important;
    border-radius: 6px !important;
    position: relative;
    /* height: var(--button-size); */
    /* width: var(--button-size); */
}

.activities-picker_sessionContent__DeCig {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 8px;
}

.activities-picker_sessionThumb__8kPyq {
    -webkit-flex: 1 1;
            flex: 1 1;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
}

.activities-picker_sessionTitle__39H_u {
    font-size: 8px;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    overflow: hidden;
}

.tags-picker_tagsList__13ZsL {
    --tagOffset: 8px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}

.tags-picker_chip__2zNbM {
    margin-bottom: 0;
    margin-top: calc(var(--tagOffset) * 2) !important;
    margin-right: var(--tagOffset) !important;
}

.screen-block_wrapper__2JFhp {
    display: -webkit-flex;
    display: flex;
    margin-bottom: 48px;
}

.screen-block_wrapper__2JFhp:first-of-type {
    margin-top: 24px;
}
.screen-block_container__1iERP {
    background-color: var(--white);
    border-radius: 8px;
    -webkit-flex: 1 1;
            flex: 1 1;
    padding: 0 24px;
}

/* Arrows */
.screen-block_arrows__1yO6o {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin-left: 24px;
    margin-top: 64px;
}

.screen-block_arrowUp__2rUdr {
    margin-bottom: 8px;
}

/* Header */
.screen-block_header___-u-7 {
    font-size: 10px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    letter-spacing: 0.4%;
    font-weight: var(--fw-bold);
    padding: 24px 0;
    border-bottom: 1px solid rgba(var(--green-dark-rgb), 0.1);
}

.screen-block_headerLeft__wnN8- {
    -webkit-flex: 1 1;
            flex: 1 1;
}

.screen-block_typeText__2CjdD {
    text-transform: uppercase;
}

.screen-block_idText__3juWf {
    margin-top: 4px;
    opacity: 0.3;
}

/* Body */
.screen-block_body__1q_NI {
    padding: 24px 0;
}

/* Footer */
.screen-block_footer__1MXod {
    border-top: 1px solid rgba(var(--green-dark-rgb), 0.1);
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    padding: 24px 0;
}

.screen-block_footer__1MXod .screen-block_formControl__165fm {
    margin: 0 16px;
}

/* Input */
.screen-block_inputGroup__qOjJ9 + .screen-block_inputGroup__qOjJ9 {
    margin-top: 32px;
}

.screen-block_inputGroupTitle__3-rut {
    font-size: 12px;
    font-weight: var(--fw-bold);
    text-transform: uppercase;
    letter-spacing: 0.4px;
    margin-bottom: 8px;
}
.screen-block_inputRow__1js07 {
    display: -webkit-flex;
    display: flex;
    margin-left: -16px;
    margin-right: -16px;
}

.screen-block_formControl__165fm {
    margin: 16px;
    width: 250px;
}

.screen-block_formControlLG__3mg43 {
    width: 350px;
}

.screen-block_formControlXL__2iCJk {
    width: 532px;
}

.screen-block_formInput__1J-am {
    width: 100% !important;
}

.screen-block_formControlDate__7AOj8 .MuiIconButton-root {
    color: var(--green-dark) !important;
}

.experts-carousel_grid__MqQ6f {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    grid-gap: 16px;
    gap: 16px;
}

.experts-carousel_gridItem__3psHI {
    width: calc(12.5% - 14px);
}

.experts-carousel_addButton__1nqzO {
    border: 1px solid rgba(0, 0, 0, 0.23) !important;
    border-radius: 6px !important;
    height: 100%;
    min-height: 125px;
}

.experts-carousel_addButton__1nqzO:hover {
    border-color: rgba(0, 0, 0, 0.87) !important;
}

.experts-carousel_session__6BINo {
    width: 100% !important;
}

.experts-picker-item_experts__39mvq {
    display: block;
    text-align: left;
    color: var(--green-dark);
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.1) !important;
    border-radius: 6px;
    position: relative;
}

.experts-picker-item_experts__39mvq::after {
    content: '';
    display: block;
    padding-bottom: 100%;
}

.experts-picker-item_background__Y-r8r {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 6px;
    opacity: 0.4;
}

.experts-picker-item_expertsContent__pKmrB {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 8px;
}

.experts-picker-item_expertsThumb__3ZdUq {
    -webkit-flex: 1 1;
            flex: 1 1;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
}

.experts-picker-item_expertsTitle__1DuCY {
    font-size: 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    overflow: hidden;
}

.experts-picker-item_experts__39mvq:hover {
    border-color: rgba(0, 0, 0, 0.87) !important;
}

.experts-picker-item_expertsWithButtons__2JxTR:hover .experts-picker-item_expertsButtons__1Ms17 {
    opacity: 1;
}

.experts-picker-item_expertsWithButtons__2JxTR:hover .experts-picker-item_expertsThumb__3ZdUq {
    opacity: 0;
}

.experts-picker-item_expertsWithButtons__2JxTR:hover .experts-picker-item_expertsTitle__1DuCY {
    opacity: 0;
}

.experts-picker-item_expertsWithButtons__2JxTR:hover .experts-picker-item_statusChip__1zSXZ {
    display: none;
}

.experts-picker-item_expertsButtons__1Ms17 {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-direction: column;
            flex-direction: column;
    opacity: 0;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    /* background-color: rgba(255, 255, 255, 0.8); */
    border-radius: 6px;
    padding: 8px;
}

.experts-picker-item_deleteButton__2Le70 {
    margin: 0 auto !important;
    display: block !important;
}

.experts-picker-item_arrowButtons__3amQJ {
    display: -webkit-flex;
    display: flex;
}

.experts-picker-item_emptyExperts__3dCcx {
    border: 1px solid rgba(0, 0, 0, 0.23) !important;
    width: 100%;
}

.experts-picker-item_statusChip__1zSXZ {
    position: absolute;
    right: 8px;
    top: 8px;
}

.experts-picker-item_warningIcon__Luj_a {
    position: absolute;
    right: 8px;
    top: 8px;
}

.experts-picker-content_container__PQhK1 {
    display: block;
}

.experts-picker-content_divider__2OaKR {
    -webkit-align-self: stretch;
            align-self: stretch;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    width: 32px;
}

.experts-picker-content_new__35rc6 {
    margin-top: 16px;
}

.experts-picker-content_experts__3RNgy {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    height: 320px;
    overflow-y: scroll;
    position: relative;
}

.experts-picker-content_expert__ywinf {
    margin-bottom: 16px;
}

.experts-picker-content_expertContent__pmzGE {
    overflow: hidden;
    white-space: nowrap;
}

.experts-picker-content_expertTitle__30mRK {
    text-overflow: ellipsis;
}

.programs-carousel_grid__50TvB {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    grid-gap: 16px;
    gap: 16px;
}

.programs-carousel_gridItem__jPuCe {
    width: calc(12.5% - 14px);
}

.programs-carousel_addButton__x-w3- {
    border: 1px solid rgba(0, 0, 0, 0.23) !important;
    border-radius: 6px !important;
    height: 100%;
}

.programs-carousel_addButton__x-w3-:hover {
    border-color: rgba(0, 0, 0, 0.87) !important;
}

.programs-carousel_session__2ZaxN {
    padding-top: 100%;
    width: 100% !important;
}

.app-screen_container__2gh8Y {
    padding-bottom: 100px;
    padding-top: 40px;
}
.app-screen_section__3dcXl {
    margin-bottom: 88px;
}

.app-screen_section__3dcXl:not(:first-of-type) {
    margin-top: 48px;
}
.app-screen_sectionHead__3GfeT {
    margin-bottom: 32px;
}

.app-screen_sectionTitle__5NI3S {
    font-size: 14px;
    font-weight: var(--fw-black);
    margin-bottom: 8px;
    text-transform: uppercase;
    letter-spacing: 0.4px;
}

.app-screen_sectionText__34nIT {
    opacity: 0.7;
    font-size: 14px;
}
.app-screen_sectionContent__1dY5W {
    margin-top: 48px;
}

.error-message_container__2lK_H {
    -webkit-align-items: center;
            align-items: center;
    display: -webkit-inline-flex;
    display: inline-flex;
    padding: 24px;
    border-radius: 6px;
    background-color: var(--main-pink-color);
}

.error-message_text__4LK2j {
    margin-left: 8px;
}

/* Table */
.programs-overview_table__5DvoV {
    border: none;
    border-collapse: collapse;
    height: 100%;
    width: 100%;
}

.programs-overview_row__KyL71 {
    height: 100%;
    width: 100%;
}

.programs-overview_row__KyL71:hover .programs-overview_cellLink__12Uje {
    background-color: rgba(var(--green-dark-rgb), 0.05);
}

.programs-overview_rowDeprecated__odrnN {
    opacity: 0.5;
}

.programs-overview_th__2G4lf {
    text-align: left;
    text-transform: uppercase;
    font-weight: var(--fw-black);
    font-size: 10px;
    letter-spacing: 0.2px;
    opacity: 0.6;
    padding-bottom: 8px;
}

.programs-overview_cell__1de9j {
    font-size: 14px;
    height: 100%;
    border-bottom: 1px solid rgba(var(--green-dark-rgb), 0.1);
}

.programs-overview_cell__1de9j:first-child .programs-overview_cellLink__12Uje {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    padding-left: 24px;
}

.programs-overview_cell__1de9j:last-child .programs-overview_cellLink__12Uje {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    padding-right: 24px;
}

.programs-overview_cellLink__12Uje {
    color: var(--green-dark);
    -webkit-align-items: center;
            align-items: center;
    display: -webkit-flex;
    display: flex;
    height: 100%;
    padding-left: 8px;
    padding-bottom: 16px;
    padding-top: 16px;
    text-decoration: none;
    width: 100%;
}

.programs-overview_titleCell__Biw_X {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.programs-overview_thumbCell__2ZBPw {
    position: relative;
}

.programs-overview_thumbImage__bJlQx {
    display: block;
    height: 80px;
    width: 80px;
}

.programs-overview_noImage__3GZ6M {
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    display: -webkit-flex;
    display: flex;
    opacity: 0.3;
    height: 80px;
    width: 80px;
}

.programs-overview_titleCellText__3y5SD {
    font-weight: var(--fw-bold);
}

.programs-overview_status__38zwI {
    position: absolute;
    bottom: 0;
    /* transform: translateY(50%); */
}

.content-detail-blocks_blocks__2acm4 {
    display: -webkit-flex;
    display: flex;
}

.content-detail-blocks_block__3pvDj + .content-detail-blocks_block__3pvDj {
    margin-left: 32px;
}

.content-detail-blocks_block__3pvDj {
    background-color: var(--white);
    border-radius: 8px;
    padding: 24px;
    width: 400px;
}

.content-detail-blocks_type__1iCq6 {
    font-size: 12px;
    font-weight: var(--fw-bold);
    margin-bottom: 16px;
    text-transform: uppercase;
}

.content-detail-blocks_propertyTitle__1qjZ0 {
    text-transform: uppercase;
    font-size: 10px;
    font-weight: var(--fw-bold);
    opacity: 0.4;
    letter-spacing: 0.4px;
}

.content-detail-blocks_propertyValue__1UXti {
    margin-top: 4px;
}

.content-detail-blocks_propertyValueEmpty__GCDc- {
    opacity: 0.8;
}

.content-detail-blocks_property__1j-py + .content-detail-blocks_property__1j-py {
    margin-top: 32px;
}

.content-detail-blocks_propertyList__3kGa8 {
    padding: 0;
    margin: 0;
    list-style: none;
}

.content-detail-blocks_propertyListItem__1B-9H + .content-detail-blocks_propertyListItem__1B-9H {
    margin-top: 8px;
}

.content-detail-blocks_propertyListItemTitle__UUS4S {
    font-size: 14px;
    font-weight: var(--fw-bold);
}

.content-detail-blocks_propertyListItemText__w5x7E {
    display: block;
}

.content-detail-blocks_image__2DV6B {
    padding-top: 100%;
    position: relative;
    width: 100%;
}

.content-detail-blocks_imageInner__2FoZJ {
    background-size: contain;
    background-position: center center;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.json-editor-dialog_header__2Oigi {
    background-color: var(--green-dark);
    -webkit-align-items: center;
            align-items: center;
    display: -webkit-flex;
    display: flex;
    height: var(--header-height);
    padding: 0 16px;
}

.json-editor-dialog_headerText__2rw7v {
    -webkit-flex: 1 1;
            flex: 1 1;
}

.json-editor-dialog_headerTitle__dKf1h {
    color: var(--white);
    font-size: 12px;
    font-weight: var(--fw-bold);
    letter-spacing: 0.4px;
    text-transform: uppercase;
}

.json-editor-dialog_bottomBar__2bhK2 {
    background-color: var(--gray-light);
    display: -webkit-flex;
    display: flex;
    padding: 8px 16px;
}

.json-editor-dialog_bottomBarContent__3v_78 {
    display: -webkit-flex;
    display: flex;
    -webkit-flex: 1 1;
            flex: 1 1;
}

.json-editor-dialog_bottomBarContentItem__3oBsV {
    margin-left: 16px;
}

.json-editor-dialog_datepicker__1S3A1 {
    /* display: none !important; */
    visibility: hidden;
    overflow: hidden;
    height: 0;
}

.json-editor-dialog_container__u49gk {
    height: 100%;
}

.json-editor-dialog_container__u49gk > div {
    height: 100%;
}

.json-editor-dialog_closeButton__2DXsK {
    color: var(--white) !important;
}

.jsoneditor {
    border: 0;
}

.jsoneditor-menu {
    background-color: var(--green-dark);
    border-bottom: 0;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
}
.jsoneditor-menu > button {
    color: var(--green-dark) !important;
}

.jsoneditor-statusbar {
    padding: 0 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.other-program-versions_container__3gNAf {
}

.other-program-versions_title__1tt1k {
    font-size: 14px;
    opacity: 0.8;
}

.other-program-versions_item__1GV58 {
    -webkit-align-items: center;
            align-items: center;
    display: -webkit-flex;
    display: flex;
    position: relative;
    padding: 16px 8px;
}

.other-program-versions_itemDeprecated__2vHNn {
    opacity: 0.5;
}

.other-program-versions_item__1GV58 {
    border-bottom: 1px solid rgba(var(--green-dark-rgb), 0.1);
}

.other-program-versions_itemThumb__13Sl0 {
    position: relative;
    width: 48px;
}

.other-program-versions_itemThumb__13Sl0 img {
    display: block;
    max-width: 100%;
}

.other-program-versions_itemNoImage__1oJLX {
    display: -webkit-flex;
    display: flex;
    height: 48px;
    width: 100%;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    opacity: 0.2;
}

.other-program-versions_itemStatus__8kEXX {
    position: absolute;
    right: 0;
    bottom: 4px;
}

.other-program-versions_itemText__2LtPI {
    display: -webkit-flex;
    display: flex;
    -webkit-flex: 1 1;
            flex: 1 1;
    margin-left: 16px;
}

.other-program-versions_itemTitle__37t6H {
    margin-left: 4px;
}

.other-program-versions_itemVersion__1ytVs {
    font-weight: var(--fw-bold);
}

.other-program-versions_itemLink__3M72n {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
}

.other-program-versions_itemLink__3M72n:hover {
    background-color: rgba(var(--green-dark-rgb), 0.1);
}

.program-modules_module__2eeYt {
    margin-bottom: 32px;
}

.program-modules_module__2eeYt + .program-modules_module__2eeYt {
    border-top: 1px dashed rgba(var(--green-dark-rgb), 0.1);
    padding-top: 32px;
}

.program-modules_moduleTitle__bByqV {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    font-weight: var(--fw-bold);
    font-size: 14px;
}

.program-modules_lockIcon__xyQt8 {
    font-size: 16px !important;
    margin-right: 4px !important;
}

.program-modules_moduleInfo__1cLFe {
    display: -webkit-flex;
    display: flex;
    margin-top: 4px;
    opacity: 0.5;
}

.program-modules_moduleInfoItem__2ZyNN + .program-modules_moduleInfoItem__2ZyNN {
    margin-left: 16px;
}

.program-modules_moduleInfoItem__2ZyNN {
    font-size: 14px;
}

.program-modules_moduleDescription__2vMTH {
    margin-top: 16px;
    max-width: 640px;
}

.program-modules_sessions__Vabfk {
    display: -webkit-flex;
    display: flex;
    overflow: scroll;
    padding-bottom: 8px;
}

.program-modules_sessionsWrapper__3G34g {
    margin-top: 40px;
    position: relative;
}

.program-modules_session__niHcw {
    background-color: var(--white);
    padding: 24px;
    border-radius: 8px;
}

.program-modules_session__niHcw + .program-modules_session__niHcw {
    margin-left: 24px;
}

.program-modules_sessionsGradient__3XeRR {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    width: 48px;
    background: rgb(var(--off-white-rgb));
    background: linear-gradient(
        90deg,
        rgba(var(--off-white-rgb), 0) 0%,
        rgba(var(--off-white-rgb), 1) 100%
    );
    bottom: 0;
    z-index: 1;
}

.program-modules_danger__2NyuC {
    color: var(--error-color);
    font-style: italic;
}

.program-modules_sessionNumber__3CaQQ {
    font-size: 12px;
    font-weight: var(--fw-bold);
    text-transform: uppercase;
}

.program-modules_sessionTitle__P-iqQ {
    font-weight: var(--fw-bold);
    margin-top: 4px;
    font-size: 14px;
}

.program-modules_sessionHead__2-h5J {
    margin-bottom: 24px;
}

.program-modules_property__3ZYmo + .program-modules_property__3ZYmo {
    margin-top: 24px;
}

.program-modules_propertyTitle__ugywk {
    text-transform: uppercase;
    font-size: 10px;
    font-weight: var(--fw-bold);
    opacity: 0.4;
    letter-spacing: 0.4px;
}

.program-modules_propertyValue__2dH99 {
    margin-top: 4px;
    font-size: 14px;
    width: 180px;
}
.program-modules_empty__3Rz2v {
    -webkit-align-items: center;
            align-items: center;
    display: -webkit-inline-flex;
    display: inline-flex;
    padding: 24px;
    border-radius: 6px;
    background-color: var(--main-pink-color);
}

.program-modules_emptyText__2oNCr {
    margin-left: 8px;
}

.program_container__1PjqG {
    padding-top: 40px;
    padding-bottom: 100px;
}

.program_section__2qvfr {
    margin-bottom: 88px;
}

.program_section__2qvfr:not(:first-of-type) {
    margin-top: 48px;
}

.program_sectionHead__2NCRX {
    margin-bottom: 32px;
}

.program_sectionTitle__3usgq {
    font-size: 14px;
    font-weight: var(--fw-black);
    margin-bottom: 8px;
    text-transform: uppercase;
    letter-spacing: 0.4px;
}

.program_sectionText__3ndhp {
    opacity: 0.7;
    font-size: 14px;
}

.program_sectionContent__1XwZE {
    margin-top: 48px;
}

.program_propertiesAndVersion__2VYMV {
    display: -webkit-flex;
    display: flex;
}

.program_propertiesContainer__2aBHF {
}

.program_thumbnail__2su5a {
    border: 1px dashed rgba(var(--green-dark-rgb), 0.2);
    border-radius: 8px;
    padding: 16px;
    width: 160px;
}

.program_noImage__3ye4S {
    display: -webkit-flex;
    display: flex;
    opacity: 0.5;
    -webkit-flex-direction: column;
            flex-direction: column;
    height: 120px;
    width: 100%;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    text-align: center;
}

.program_noImageText__2LIyG {
    font-size: 12px;
    margin-top: 8px;
}

.program_thumbnail__2su5a img {
    display: block;
    max-width: 100%;
}

.program_properties__2Uy5n {
    -webkit-flex: 1 1;
            flex: 1 1;
    padding-top: 24px;
}

.program_properties__2Uy5n + .program_properties__2Uy5n {
    margin-left: 64px;
}

.program_property__8BAUz {
    max-width: 360px;
}

.program_propertiesRow__S5qOn {
    display: -webkit-flex;
    display: flex;
}

.program_propertiesRow__S5qOn + .program_propertiesRow__S5qOn {
    margin-top: 48px;
}

.program_propertiesRowItem__2aa5o + .program_propertiesRowItem__2aa5o {
    margin-left: 64px;
}

.program_propertiesRowItem__2aa5o > .program_property__8BAUz + .program_property__8BAUz {
    margin-top: 40px;
}

.program_propertyTitle__23bLM {
    text-transform: uppercase;
    font-size: 10px;
    font-weight: var(--fw-bold);
    opacity: 0.4;
    letter-spacing: 0.4px;
}

.program_propertyValue__1PfzJ {
    margin-top: 4px;
}

.program_propertyValueEmpty__28RIP {
    opacity: 0.8;
}

.program_createdByImage__29uw0 {
    display: block;
    max-width: 100%;
}

.program_version__5ndeT {
    -webkit-align-self: flex-start;
            align-self: flex-start;
    background-color: var(--white);
    border-radius: 8px;
    padding: 24px;
    /* margin-left: 88px; */
}

.program_latestVersionInfo__3zdje {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: var(--fw-bold);
    opacity: 0.8;
    letter-spacing: 0.4px;
}

.program_latestVersionInfoSuccess__Fmtaj .program_latestVersionInfoText__29xs6 {
    color: var(--purple);
}
.program_latestVersionInfoError__2Efqm .program_latestVersionInfoText__29xs6 {
    color: var(--error-color);
}

.program_latestVersionIcon__1PTwq {
    margin-right: 4px;
}

.program_latestVersionIconSuccess__2I3yJ {
    color: var(--purple) !important;
}

.program_latestVersionIconError__A1v1q {
    color: var(--error-color) !important;
}

.program_otherVersions__3yoNj {
    margin-top: 24px;
}

.program_otherVersionsList__G7mLp {
    margin-top: 24px;
}

.program_btnMakeLive__xGhDz {
    margin-left: 24px;
}
.program_empty__1sZK3 {
    -webkit-align-items: center;
            align-items: center;
    display: -webkit-inline-flex;
    display: inline-flex;
    padding: 24px;
    border-radius: 6px;
    background-color: var(--main-pink-color);
}

.program_emptyText__2hLc5 {
    margin-left: 8px;
}

.course-chat_mainContainer__1YzVZ {
  padding: 3%;
}
.course-chat_titleContainer__Y4Du8 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  justify-items: center;
}

.course-chat_tableItem___IAz- {
  text-decoration: none;
}
.course-chat_tableItem___IAz-:hover {
  background-color: var(--main-pink-color);
}

.course-chat_headerContainer__1ePqT {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
}
.course-chat_buttonContainer__1HS0O {
  margin-right: 5vw;
}

.course-chat_row__BH3t5 {
  transition: background-color 0.3s ease-in-out;
}
.course-chat_row__BH3t5:hover {
  background-color: rgba(var(--green-dark-rgb), 0.05);
  cursor: pointer;
}
.course-chat_mainTable__8icbk {
  margin-top: 24px;
  width: 100%;
}
.course-chat_thumbnail__zG4kb {
  display: block;
  max-width: 100%;
}
.course-chat_thumbnailWrapper__26dbK {
  height: 64px;
  width: 64px;
  display: block;
  position: relative;
}

.course-chat_noImage__1rZPm {
  display: -webkit-flex;
  display: flex;
  height: 64px;
  width: 64px;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  opacity: 0.3;
}

.course-chat_status__28SuV {
  position: absolute;
  bottom: 0;
  -webkit-transform: translateY(50%);
          transform: translateY(50%);
}

.course-chat_showUnpublished__3Bor2 {
  padding-left: 2%;
}

.course-chat_cell__2Yaak {
  font-size: 14px;
  padding-top: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid rgba(var(--green-dark-rgb), 0.1);
  /* white-space: nowrap; */
}

/* .cell:first-child {
  padding-left: 40px;
} */

.course-chat_cell__2Yaak:last-child {
  padding-right: 40px;
}

.course-chat_cellBold__15N3O {
  font-weight: var(--fw-bold);
  max-width: 160px;
}
.course-chat_columnTitle__ZHb_H {
  opacity: 0.6;
  letter-spacing: 0.2px;
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  text-transform: uppercase;
  font-size: 10px;
}

.course-chat_search__BdbPa {
  margin-left: 40px;
  margin-bottom: 24px;
}


.page-section_section__30ojC {
    margin-bottom: 88px;
}

.page-section_section__30ojC:not(:first-of-type) {
    margin-top: 48px;
}

.page-section_sectionHead__1vz2K {
    display: -webkit-flex;
    display: flex;
    margin-bottom: 32px;
}

.page-section_sectionHeadContent__2Cd-V {
    -webkit-flex: 1 1;
            flex: 1 1;
}

.page-section_sectionTitle__xBXWv {
    font-size: 14px;
    font-weight: var(--fw-black);
    margin-bottom: 8px;
    text-transform: uppercase;
    letter-spacing: 0.4px;
}

.page-section_sectionText__26NL2 {
    opacity: 0.7;
    font-size: 14px;
}

.page-section_sectionContent__1h5_i {
    margin-top: 48px;
}

.content-tag-info_blockTitle__2bCPX {
    font-size: 12px;
    font-weight: var(--fw-bold);
    margin-bottom: 8px;
}

.content-tag-info_block__2L2h- + .content-tag-info_block__2L2h- {
    margin-top: 40px;
}

.content-tag-info_grid__2F9he {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    grid-gap: 16px;
    gap: 16px;
}

.content-tag-info_gridItem__bptuP {
    width: calc(10% - 14px);
}

.content-tag-info_blockItem__1WR4j {
    background-color: var(--bg-story);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 6px;
    color: var(--green-dark);
    padding: 16px 8px;
    text-decoration: none;
}

.content-tag-info_blockItem__1WR4j:hover {
    border-color: rgba(0, 0, 0, 0.87) !important;
}

.content-tag-info_blockItemScreen__qPT9C {
    text-transform: uppercase;
    font-size: 10px;
    letter-spacing: 0.4px;
    font-weight: var(--fw-bold);
    opacity: 0.7;
    margin-bottom: 4px;
}

.content-tag-info_blockItemTitle__3X9Su {
    font-size: 14px;
}

.tags_container__2vVgM {
    padding-top: 40px;
    padding-bottom: 100px;
}

.sidebar_container__3lh2L {
    background-color: var(--light-red);
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    height: 100%;
    padding-bottom: 32px;
    width: var(--sidebar-width);
}

.sidebar_head__2s43F {
    background-image: url(/static/media/scribble-purple.af3e56ce.png);
    background-size: 54px 46px;
    background-repeat: no-repeat;
    background-position: 0 8px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    height: var(--header-height);
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
}

.sidebar_logo__3v5zy {
    width: 64px;
}

.sidebar_logo__3v5zy img {
    max-width: 100%;
}

.sidebar_navigation__20MOB {
    -webkit-flex-grow: 1;
            flex-grow: 1;
    padding-top: 48px;
}

.sidebar_list__2vS_P {
    margin-bottom: 24px !important;
    padding: 0 8px !important;
}

.sidebar_subheader__3Mot- {
    color: var(--green-dark-faded);
    text-transform: uppercase;
    font-weight: var(--fw-black) !important;
    font-size: 10px !important;
    letter-spacing: 0.5px;
}

.sidebar_user__7gtF- {
    margin: 0 auto;
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
}

.sidebar_userAvatarButton__2aNug {
    border-radius: 50%;
    border: 0;
    display: block im !important;
    box-shadow: rgb(0 0 0 / 20%) 0px 2px 1px -1px,
        rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px;
    padding: 0;
    transition: -webkit-transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
    outline: 0;
}

.sidebar_userAvatarButton__2aNug:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
}

.sidebar_link__1T0PR {
    color: var(--green-dark);
    margin-bottom: 16px;
    opacity: 0.7;
    transition: opacity 0.3s ease-in-out;
}

.sidebar_link__1T0PR:hover {
    opacity: 1;
}

.sidebar_linkActive__1TZUe {
    background-color: rgba(var(--green-dark-rgb), 0.04) !important;
    border-radius: 8px;
    color: var(--purple);
    opacity: 1;
}

.sidebar_linkIcon__2ffM7 {
    min-width: 0 !important;
}

.sidebar_linkText__2CiBh {
    padding-left: 16px;
}

.sidebar_linkTextBody__9rye- {
    font-size: 14px !important;
}

@media (max-width: 1300px) {
    .sidebar_logo__3v5zy {
        width: 48px;
    }
    .sidebar_subheader__3Mot- {
        display: none;
    }

    .sidebar_list__2vS_P {
        margin-bottom: 40px !important;
    }

    .sidebar_linkText__2CiBh {
        display: none;
    }
}

.landing_mainContainer__2PEez {
  -webkit-align-items: center;
          align-items: center;
  background-color: var(--off-white);
  -webkit-justify-content: center;
          justify-content: center;
  display: -webkit-flex;
  display: flex;
  height: 100%;
  -webkit-flex-direction: row;
          flex-direction: row;
  text-align: center;
  width: 100%;
}

.landing_loginSection__b8BQg {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  max-width: 300px;
}

.landing_logo__LMKux {
  width: 64px;
}

.landing_logo__LMKux img {
  display: block;
  max-width: 100%;
}

.landing_collage__2oKpi {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 2;
}

.landing_scribble__rrixL {
  position: absolute;
  top: 24px;
  left: 0;
  width: 64px;
  z-index: 2;
}

.landing_ferlyLogo__OPWzX {
  position: absolute;
  left: 32px;
  bottom: 32px;
  z-index: 2;
}

.landing_ferlyDescription__1X-F2 {
  margin: 32px 0;
}

.main-router_container__kI-wW .MuiDrawer-paperAnchorDockedLeft {
    border: 0 !important;
}

.main-router_container__kI-wW {
    height: 100%;
}

.main-router_content__1LDnx {
    height: 100%;
}

.main-router_contentWithSidebar__2qm-Q {
    padding-left: var(--sidebar-width);
}

