.container {
    display: flex;
}

.item + .item {
    margin-left: 24px;
}

.buttonCreate {
    border: 1px solid var(--white) !important;
    background-color: var(--white);
    border-radius: 8px;
    height: 100%;
    padding: 32px 24px;
    width: 100px;
}

.buttonCreate:hover {
    border: 1px solid rgba(0, 0, 0, 0.23) !important;
}
